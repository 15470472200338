<template>
  <v-container class="contact-container" fluid>
    <v-row align="stretch" justify="center">
      <!-- Contact Information Card -->
      <v-col cols="12" md="8" class="contact-info">
        <v-card class="info-card">
          <v-card-title class="info-title">
            <v-card elevation="15" color="#265570">
              <h2>Contact Us</h2>
            </v-card>
          </v-card-title>
          <v-card-text class="info-text text-center">
            <v-list>
              <v-list-item>
                <v-icon left color="#094033">mdi-email</v-icon>
                <span>
                  Email: <a href="mailto:{{ contactData.email }}">{{ contactData.email }}</a>
                </span>
              </v-list-item>

              <v-list-item>
                <v-icon left color="#094033">mdi-phone</v-icon>
                <span>Phone: <a href="tel:{{ contactData.phone1 }}">{{ contactData.phone1 }}</a></span>
              </v-list-item>
              <v-list-item>
                <v-icon left color="#094033">mdi-phone</v-icon>
                <span>Phone: <a href="tel:{{ contactData.phone2 }}">{{ contactData.phone2 }}</a></span>
              </v-list-item>

              <v-list-item>
                <v-icon left color="#094033">mdi-map-marker</v-icon>
                <span>Address: {{ contactData.address }}</span>
              </v-list-item>
            </v-list>
            <!-- Embedded Map -->
            <div class="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31711.70829216511!2d39.46614199521867!3d13.496091120218883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164c8031de27e78b%3A0xb72460a920a6df5a!2sMekelle%2C%20Ethiopia!5e0!3m2!1sen!2sus!4v1692642232396!5m2!1sen!2sus"
                width="100%"
                height="250"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Contact Form -->
      <!-- <v-col cols="12" md="6" class="contact-form">
        <v-card class="form-card">
          <v-card-title class="form-title">
            <v-card elevation="15" color="#094033">
              <h2>Get In Touch</h2>
            </v-card>
          </v-card-title>
           <v-card-text>
            <v-form @submit.prevent="submitContactForm">
              <v-text-field v-model="form.name" label="Name" required outlined></v-text-field>
              <v-text-field v-model="form.email" label="Email" required outlined></v-text-field>
              <v-text-field v-model="form.phoneNumber" label="Phone Number" required outlined></v-text-field>
              <v-textarea v-model="form.message" label="Message" required outlined></v-textarea>
              <v-btn class="submit-btn" type="submit" color="#094033" block large>Send Message</v-btn>
            </v-form>
          </v-card-text> 
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      form: {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      },
      contactData: {
        email: "info@micotechs.com",
        phone1: "+251986506868",
        phone2:"+251911420976",
        address: "Mekelle, Ethiopia",
      },
    };
  },
  methods: {
    submitContactForm() {
      // API call to submit contact form
      console.log("Form Submitted", this.form);
    },
  },
};
</script>

<style scoped>
.contact-container {
  background-color: #e3f2fd; /* Light blue background */
  padding: 60px 20px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  min-height: 100vh; /* Make sure the container has a minimum height */
}

.contact-info, .contact-form {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Use stretch to fill the height */
}

.info-card, .form-card {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  flex-grow: 1; /* Allow the card to grow and fill the space */
}

.info-card:hover, .form-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.info-title h2, .form-title h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 2.2rem;
  color: white; /* Primary color */
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 1px;
}

.info-text v-list-item-title {
  font-size: 1.1rem;
  color: #555;
}

.map-container {
  margin-top: 20px; /* Margin for the map */
  border-radius: 10px;
  overflow: hidden;
}

a {
  color:#094033; /* Primary color */
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #094033; /* Darker shade on hover */
  text-decoration: underline;
}

.submit-btn {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #094033; /* Darker shade on hover */
}
</style>
